import { types, Instance, SnapshotOut } from "mobx-state-tree";
import api from "services/api/api";

export const MceModel = types
  .model("Mces")
  .props({
    totalSensor: types.maybeNull(types.number),
    disconnected: types.maybeNull(types.number),
    connected: types.maybeNull(types.number),
    nameSensor: types.maybeNull(types.string),
    isLoading: types.maybeNull(types.boolean),
  })
  .actions((self) => {
    function setCard(payload: any) {
      self.totalSensor = payload.totalSensor;
      self.disconnected = payload.disconnected;
      self.connected = payload.connected;
      self.nameSensor = payload.nameSensor;
    }
    function setLoading(loading: any) {
      self.isLoading = loading;
    }
    return {
      setCard,
      setLoading,
    };
  })
  .actions((self) => ({
    getCard: async () => {
      try {
        self.setLoading(true);
        const mce = api.mce;
        const response = await mce.getCard();
        self.setCard(response?.answer);
        self.setLoading(false);
      } catch (error) {
        console.log(error);
      }
    },
  }));

type MceModelType = Instance<typeof MceModel>;
export type Mces = MceModelType;
type MecsSnapshotType = SnapshotOut<typeof MceModel>;
export type McesSnapshot = MecsSnapshotType;
export const createMceDefaultModel = () =>
  types.optional(MceModel, {
    totalSensor: 0,
    disconnected: 0,
    connected: 0,
    nameSensor: "",
    isLoading: false,
  });
