import { types, Instance, SnapshotOut } from "mobx-state-tree";
import api from "services/api/api";

export const DwModel = types
  .model("Dw")
  .props({
    count: types.maybeNull(types.number),
    on: types.maybeNull(types.number),
    off: types.maybeNull(types.number),
    title: types.maybeNull(types.string),
    isLoading: types.maybeNull(types.boolean),
  })
  .actions((self) => {
    function setDw(payload: any) {
      self.count = payload.count;
      self.off = payload.off;
      self.on = payload.on;
      self.title = payload.title;
    }
    function setLoading(isLoading: any) {
      self.isLoading = isLoading;
    }
    return {
      setDw,
      setLoading,
    };
  })
  .actions((self) => ({
    getCard: async () => {
      try {
        self.setLoading(true);
        const dw = api.dw;
        const response = await dw.getCard();
        self.setDw(response?.answer);
        self.setLoading(false);
      } catch (error) {
        console.log(error);
      }
    },
  }));

type DwModelType = Instance<typeof DwModel>;
export type Dw = DwModelType;
type DwSnapshotType = SnapshotOut<typeof DwModel>;
export type DwSnapshot = DwSnapshotType;
export const createDwDefaultModel = () =>
  types.optional(DwModel, {
    count: 0,
    on: 0,
    off: 0,
    title: "",
    isLoading: false,
  });
