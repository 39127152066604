import {
  applySnapshot,
  getSnapshot,
  Instance,
  SnapshotOut,
  types,
} from "mobx-state-tree";
import {
  createParkingAlarmDefaultModel,
  createFrameDefaultModel,
  createUserDefaultModel,
  createCounterDefaultModel,
  createTransitDefaultModel,
  createParkingDefaultModel,
  createMceDefaultModel,
  createMceAlarmDefaultModel,
  createGasDefaultModel,
  createGasAlarmDefaultModel,
  createDwDefaultModel,
  createDwAlarmDefaultModel,
  createAlarmsDefaultModel,
  createMapAlarmDefaultModel,
  createFreezerDefaultModel,
  createFreezerAlarmDefaultModel,
  createLakeDefaultModel,
  createLakeAlarmDefaultModel,
  createWeatherDefaultModel,
  createWeatherAlarmDefaultModel,
  createResidentialDefaultModel,
  createResidentialAlarmDefaultModel
} from "models";

export const resetStore = (self: any) => {
  let initialState: any;
  return {
    afterCreate() {
      initialState = getSnapshot(self);
    },
    resetStore() {
      applySnapshot(self, initialState);
    },
  };
};

export const RootStoreModel = types.model("RootStore").props({
  parkingAlarmsStore: createParkingAlarmDefaultModel(),
  usersStore: createUserDefaultModel(),
  countersStore: createCounterDefaultModel(),
  transitStore: createTransitDefaultModel(),
  parkingsStore: createParkingDefaultModel(),
  mcesStore: createMceDefaultModel(),
  mcesAlarmStore: createMceAlarmDefaultModel(),
  gasStore: createGasDefaultModel(),
  gasAlarmStore: createGasAlarmDefaultModel(),
  dwStore: createDwDefaultModel(),
  dwAlarmStore: createDwAlarmDefaultModel(),
  alarmsStore: createAlarmsDefaultModel(),
  frameStore: createFrameDefaultModel(),
  mapStore: createMapAlarmDefaultModel(),
  freezerStore: createFreezerDefaultModel(),
  freezerAlarmStore: createFreezerAlarmDefaultModel(),
  lakeStore: createLakeDefaultModel(),
  lakeAlarmStore: createLakeAlarmDefaultModel(),
  weatherStore: createWeatherDefaultModel(),
  weatherAlarmStore: createWeatherAlarmDefaultModel(),
  residentialStore: createResidentialDefaultModel(),
  residentialAlarmStore: createResidentialAlarmDefaultModel(),
});

export const rootStore = RootStoreModel.create();
export type RootStore = Instance<typeof RootStoreModel>;
export type RootStoreSnapshot = SnapshotOut<typeof RootStoreModel>;
