import { types, Instance, SnapshotOut } from "mobx-state-tree";
import api from "services/api/api";

export const FreezersModel = types
  .model("Freezers")
  .props({
    free: types.maybeNull(types.number),
    totalSensor: types.maybeNull(types.number),
    busy: types.maybeNull(types.number),
    nameSensor: types.maybeNull(types.string),
    isLoading: types.maybeNull(types.boolean),
  })
  .actions((self) => {
    function setFreezer(payload: any) {
      self.free = payload.free;
      self.totalSensor = payload.totalSensor;
      self.busy = payload.busy;
      self.nameSensor = payload.nameSensor;
    }
    function setLoading(loading: any) {
      self.isLoading = loading;
    }
    return {
      setFreezer,
      setLoading,
    };
  })
  .actions((self) => ({
    getCard: async () => {
      try {
        self.setLoading(true);
        const freezer = api.freezer;
        const response = await freezer.getCard();
        self.setFreezer(response?.answer);
        self.setLoading(false);
      } catch (error) {
        console.log(error);
      }
    },
  }));

type FreezersModelType = Instance<typeof FreezersModel>;
export type Freezers = FreezersModelType;
type FreezersSnapshotType = SnapshotOut<typeof FreezersModel>;
export type FreezersSnapshot = FreezersSnapshotType;
export const createFreezerDefaultModel = () =>
  types.optional(FreezersModel, {
    free: 0,
    totalSensor: 0,
    busy: 0,
    nameSensor: "",
    isLoading: false,
  });
