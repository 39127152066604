import {
  types,
  Instance,
  SnapshotOut,
  IMSTArray,
  ISimpleType,
} from "mobx-state-tree";
import { IUser } from "models/interfaces/user";
import api from "services/api/api";

const CitizenProtection = types.model("citizen").props({
  safePoint: types.maybeNull(types.boolean),
  cameraMonitoring: types.maybeNull(types.boolean),
  alarmMonitoring: types.maybeNull(types.boolean),
});

const publicWorkAndServices = types.model("public").props({
  gassensor: types.maybeNull(types.boolean),
  mce: types.maybeNull(types.boolean),
  publicLighting: types.maybeNull(types.boolean),
  floodMonitoring: types.maybeNull(types.boolean),
  connectionService: types.maybeNull(types.boolean),
});

const environment = types.model("environment").props({
  weatherStations: types.maybeNull(types.boolean),
  noiseLevel: types.maybeNull(types.boolean),
  irrigationControl: types.maybeNull(types.boolean),
  wasteManagment: types.maybeNull(types.boolean),
  riverLevel: types.maybeNull(types.boolean),
});

const logistics = types.model("logistics").props({
  carTracking: types.maybeNull(types.boolean),
  freezerMonitoring: types.maybeNull(types.boolean),
});

const transit = types.model("transit").props({
  loop: types.maybeNull(types.boolean),
  noparking: types.maybeNull(types.boolean),
  trafficLightMonitoring: types.maybeNull(types.boolean),
});

const communication = types.model("communication").props({
  gw: types.maybeNull(types.boolean),
});

const basicServices = types.model("basicServices").props({
  residentialWaterMeters: types.maybeNull(types.boolean),
  companyWaterMeters: types.maybeNull(types.boolean),
  residentialGasMeters: types.maybeNull(types.boolean),
  companyGasMeters: types.maybeNull(types.boolean),
  residentialEnergyMeters: types.maybeNull(types.boolean),
  companyEnergyMeters: types.maybeNull(types.boolean),
});

const Service = types.model("service").props({
  communication: types.maybeNull(communication),
  citizenProtection: types.maybeNull(CitizenProtection),
  publicworks_service: types.maybeNull(publicWorkAndServices),
  environment: types.maybeNull(environment),
  logistics: types.maybeNull(logistics),
  transit_transportation: types.maybeNull(transit),
  basicServicesDistribution: types.maybeNull(basicServices),
});

export const UsersModel = types
  .model("User")
  .props({
    token: types.maybeNull(types.string),
    user: types.maybeNull(types.string),
    type: types.maybeNull(types.string),
    client: types.maybeNull(types.string),
    service: types.maybeNull(Service),
    error: types.maybeNull(types.boolean),
    app: types.maybeNull(types.number),
    footer: types.maybeNull(types.array(types.string)),
  })
  .actions((self) => {
    function setUser(payload: {
      user: string;
      token: string;
      type: string;
      client: string;
      service: Instance<typeof Service>;
      app: number;
      footer: IMSTArray<ISimpleType<string>>;
    }) {
      self.user = payload.user;
      self.token = payload.token;
      self.type = payload.type;
      self.client = payload.client;
      self.service = payload.service;
      self.app = payload.app;
      self.footer = payload.footer;
    }
    function removeToken() {
      self.token = null;
      api.api.removeToken();
    }
    function setError(error: boolean | null) {
      self.error = error;
    }
    return {
      setUser,
      setError,
      removeToken,
    };
  })
  .actions((self) => ({
    login: async (usuario: IUser) => {
      self.setError(false);
      try {
        const userApi = api.login;
        const payload = {
          user: usuario.user,
          pass: usuario.pass,
          clientid: usuario.clientid,
        };
        const response = await userApi.login(payload);
        const { answer } = response;
        api.api.apisauce.headers.Authorization = `Bearer ${answer.token}`;
        self.setUser(answer);
      } catch (error) {
        self.setError(true);
      }
    },
  }));

type UsersModelType = Instance<typeof UsersModel>;
export type Users = UsersModelType;
type UsersSnapshotType = SnapshotOut<typeof UsersModel>;
export type UsersSnapshot = UsersSnapshotType;
export const createUserDefaultModel = () =>
  types.optional(UsersModel, {
    user: null,
    client: null,
    token: null,
    service: {},
    error: false,
  });
