import { types, Instance, SnapshotOut } from "mobx-state-tree";
import api from "services/api/api";

const Coordinates = types.model("coordinates").props({
  coordinates: types.array(types.number),
});

const Mce = types.model("Mce").props({
  deveui: types.maybeNull(types.string),
  id: types.maybeNull(types.string),
  connected: types.maybeNull(types.boolean),
  location: types.maybeNull(Coordinates),
});

const Loop = types.model("Loop").props({
  deveui: types.maybeNull(types.string),
  id: types.maybeNull(types.string),
  connected: types.maybeNull(types.boolean),
  location: types.maybeNull(Coordinates),
});

const Gw = types.model("Gw").props({
  deveui: types.maybeNull(types.string),
  connected: types.maybeNull(types.boolean),
  id: types.maybeNull(types.string),
  location: types.maybeNull(Coordinates),
});

const Parking = types.model("Parking").props({
  deveui: types.maybeNull(types.string),
  id: types.maybeNull(types.string),
  connected: types.maybeNull(types.boolean),
  location: types.maybeNull(Coordinates),
});

const Gas = types.model("Gas").props({
  deveui: types.maybeNull(types.string),
  id: types.maybeNull(types.string),
  connected: types.maybeNull(types.boolean),
  location: types.maybeNull(Coordinates),
});

const Maps = types.model("Maps").props({
  MCE: types.optional(types.array(Mce), []),
  Loop: types.optional(types.array(Loop), []),
  GW: types.optional(types.array(Gw), []),
  noparking: types.optional(types.array(Parking), []),
  gassensor: types.optional(types.array(Gas), []),
});

export const MapModel = types
  .model("Maps")
  .props({
    alarms: Maps,
    isLoading: types.boolean,
  })
  .actions((self) => {
    function setAlarms(alarms: any) {
      self.alarms = alarms;
    }
    function setLoading(isLoading: any) {
      self.isLoading = isLoading;
    }
    return {
      setAlarms,
      setLoading,
    };
  })
  .actions((self) => ({
    getLocations: async () => {
      try {
        self.setLoading(true);
        const mapApi = api.maps;
        const response = await mapApi.getData();
        self.setAlarms(response?.answer);
        self.setLoading(false);
      } catch (error) {
        console.log(error);
      }
    },
  }));

type MapModelType = Instance<typeof MapModel>;
export type Map = MapModelType;
type MapSnapshotType = SnapshotOut<typeof MapModel>;
export type MapSnapshot = MapSnapshotType;
export const createMapAlarmDefaultModel = () =>
  types.optional(MapModel, {
    alarms: {},
    isLoading: false,
  });
