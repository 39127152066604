import { types, Instance, SnapshotOut } from "mobx-state-tree";
import api from "services/api/api";

export const ResidentialModel = types
  .model("Residential")
  .props({
    count: types.maybeNull(types.number),
    on: types.maybeNull(types.number),
    off: types.maybeNull(types.number),
    title: types.maybeNull(types.string),
    isLoading: types.maybeNull(types.boolean),
  })
  .actions((self) => {
    function setResidential(payload: any) {
      self.count = payload.count;
      self.off = payload.off;
      self.on = payload.on;
      self.title = payload.title;
    }
    function setLoading(isLoading: any) {
      self.isLoading = isLoading;
    }
    return {
      setResidential,
      setLoading,
    };
  })
  .actions((self) => ({
    getCard: async () => {
      try {
        self.setLoading(true);
        const Residential = api.residential;
        const response = await Residential.getCard();
        self.setResidential(response?.answer);
        self.setLoading(false);
      } catch (error) {
        console.log(error);
      }
    },
  }));

type ResidentialModelType = Instance<typeof ResidentialModel>;
export type Residential = ResidentialModelType;
type ResidentialSnapshotType = SnapshotOut<typeof ResidentialModel>;
export type ResidentialSnapshot = ResidentialSnapshotType;
export const createResidentialDefaultModel = () =>
  types.optional(ResidentialModel, {
    count: 0,
    on: 0,
    off: 0,
    title: "",
    isLoading: false,
  });
