import { Instance, SnapshotOut, types } from "mobx-state-tree";
import api from "services/api/api";

const chartLineOne = types.model({
  hour: types.maybeNull(types.number),
  value: types.maybeNull(types.number),
});

const TransitModel = types.model("Transit").props({
  title: types.maybeNull(types.string),
  lineColor1: types.maybeNull(types.string),
  lineColor2: types.maybeNull(types.string),
  passCounterLane1: types.maybeNull(types.number),
  averangeSpeedLane1: types.maybeNull(types.number),
  maximunSpeedLane1: types.maybeNull(types.number),
  staticOccupancyStatusLane1: types.maybeNull(types.boolean),
  chartLine1: types.optional(types.array(chartLineOne), []),
  passCounterLane2: types.maybeNull(types.number),
  averangeSpeedLane2: types.maybeNull(types.number),
  maximunSpeedLane2: types.maybeNull(types.number),
  staticOccupancyStatusLane2: types.maybeNull(types.boolean),
  chartLine2: types.maybeNull(types.number),
  energyState: types.maybeNull(types.boolean),
});

export const TransitsModel = types
  .model("Transits")
  .props({
    transit: types.optional(types.array(TransitModel), []),
  })
  .actions((self) => {
    function setTransit(transit: any) {
      self.transit = transit;
    }
    return {
      setTransit,
    };
  })
  .actions((self) => ({
    getMeTransits: async () => {
      try {
        const device = api.transit;
        const response = await device.getDevice();
        self.setTransit(response?.answer.device);
      } catch (error) {
        console.log(error);
      }
    },
  }));

type TransitsModelType = Instance<typeof TransitsModel>;
export type Transits = TransitsModelType;
type TransitsSnapshotType = SnapshotOut<typeof TransitsModel>;
export type TransitsSnapshot = TransitsSnapshotType;
export const createTransitDefaultModel = () =>
  types.optional(TransitsModel, {
    transit: [],
  });
