import { types, Instance, SnapshotOut } from "mobx-state-tree";
import api from "services/api/api";

const chartLineOne = types.model({
  hour: types.maybeNull(types.number),
  value: types.maybeNull(types.number),
});

const CounterModel = types.model("Counter").props({
  title: types.maybeNull(types.string),
  passCounterLane1: types.maybeNull(types.number),
  averangeSpeedLane1: types.maybeNull(types.number),
  maximunSpeedLane1: types.maybeNull(types.number),
  staticOccupancyStatusLane1: types.maybeNull(types.boolean),
  chartLine1: types.maybeNull(types.array(chartLineOne)),
  passCounterLane2: types.maybeNull(types.number),
  averangeSpeedLane2: types.maybeNull(types.number),
  maximunSpeedLane2: types.maybeNull(types.number),
  staticOccupancyStatusLane2: types.maybeNull(types.boolean),
  chartLine2: types.maybeNull(types.number),
  energyState: types.maybeNull(types.boolean),
});

export const CountersModel = types
  .model("Counters")
  .props({
    counter: types.maybeNull(CounterModel),
    isLoading: types.boolean,
  })
  .actions((self) => {
    function setCounter(counter: any) {
      self.counter = counter;
    }
    function setLoading(isLoading: boolean) {
      self.isLoading = isLoading;
    }
    return {
      setCounter,
      setLoading,
    };
  })
  .actions((self) => ({
    getCard: async () => {
      try {
        self.setLoading(true);
        const counterApi = api.transit;
        const response = await counterApi.getCard();
        self.setCounter(response?.answer[0]);
        self.setLoading(false);
      } catch (error) {
        console.log(error);
      }
    },
  }));

type CountersModelType = Instance<typeof CountersModel>;
export type Counters = CountersModelType;
type CountersSnapshotType = SnapshotOut<typeof CountersModel>;
export type CountersSnapshot = CountersSnapshotType;
export const createCounterDefaultModel = () =>
  types.optional(CountersModel, {
    counter: null,
    isLoading: false,
  });
