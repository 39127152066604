import { ApisauceInstance, create } from "apisauce";
import { ApiConfig, DEFAULT_API_CONFIG } from "./api-config";
import { AlarmApi } from "./alarmApi";
import { LoginApi } from "./loginApi";
import { ReportsApi } from "./reportApi";
import { CustomApi } from "./baseApi";

/**
 * Manages all requests to the API.
 */
export class Api {
  /**
   * The underlying apisauce instance which performs the requests.
   */
  apisauce!: ApisauceInstance;

  /**
   * Configurable options.
   */
  config: ApiConfig;

  /**
   * Creates the api.
   *
   * @param config The configuration to use.
   */
  constructor(config: ApiConfig = DEFAULT_API_CONFIG) {
    this.config = config;
  }

  removeToken() {
    this.apisauce.deleteHeader("Authorization");
  }

  setToken(token: string) {
    this.apisauce.setHeader("Authorization", token);
  }

  /**
   * Sets up the API.  This will be called during the bootup
   * sequence and will happen before the first React component
   * is mounted.
   *
   * Be as quick as possible in here.
   */
  setup() {
    // construct the apisauce instance
    this.apisauce = create({
      baseURL: this.config.url,
      timeout: this.config.timeout,
      headers: {
        Accept: "application/json",
      },
    });
  }
}

const baseApi = new Api();
baseApi.setup();
const api = {
  api: baseApi,
  parking: new CustomApi(baseApi, "noparking"),
  transit: new CustomApi(baseApi, "loop"),
  alarms: new AlarmApi(baseApi),
  login: new LoginApi(baseApi),
  mce: new CustomApi(baseApi, "mce"),
  gas: new CustomApi(baseApi, "gassensor"),
  dw: new CustomApi(baseApi, "gw"),
  reports: new ReportsApi(baseApi),
  maps: new CustomApi(baseApi, "maps"),
  freezer: new CustomApi(baseApi, "freezerMonitoring"),
  lake: new CustomApi(baseApi, "riverLevel"),
  weather: new CustomApi(baseApi, "weatherStations"),
  residential: new CustomApi(baseApi, "residentialWaterMeters"),
};

export default api;
