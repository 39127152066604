import { types, Instance, SnapshotOut } from "mobx-state-tree";
import api from "services/api/api";

export const LakeModel = types
  .model("Lakes")
  .props({
    free: types.maybeNull(types.number),
    totalSensor: types.maybeNull(types.number),
    busy: types.maybeNull(types.number),
    nameSensor: types.maybeNull(types.string),
    isLoading: types.maybeNull(types.boolean),
  })
  .actions((self) => {
    function setLake(payload: any) {
      self.free = payload.free;
      self.totalSensor = payload.totalSensor;
      self.busy = payload.busy;
      self.nameSensor = payload.nameSensor;
    }
    function setLoading(loading: any) {
      self.isLoading = loading;
    }
    return {
      setLake,
      setLoading,
    };
  })
  .actions((self) => ({
    getCard: async () => {
      try {
        self.setLoading(true);
        const lake = api.lake;
        const response = await lake.getCard();
        self.setLake(response?.answer);
        self.setLoading(false);
      } catch (error) {
        console.log(error);
      }
    },
  }));

type LakesModelType = Instance<typeof LakeModel>;
export type Lakes = LakesModelType;
type LakesSnapshotType = SnapshotOut<typeof LakeModel>;
export type LakesSnapshot = LakesSnapshotType;
export const createLakeDefaultModel = () =>
  types.optional(LakeModel, {
    free: 0,
    totalSensor: 0,
    busy: 0,
    nameSensor: "",
    isLoading: false,
  });
