import { observer } from "mobx-react-lite";
import { rootStore, useStores } from "models";
import persist from "mst-persist";
import React from "react";
import { HashRouter, Route } from "react-router-dom";
import api from "services/api/api";
import "./scss/style.scss";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

const DefaultLayout = React.lazy(() => import("./layout/DefaultLayout"));
const Login = React.lazy(() => import("./views/pages/login/Login"));

const App = observer(function App() {
  const { usersStore } = useStores();

  persist("iotinmotion", rootStore).then(() => {
    if (rootStore.usersStore.token && rootStore.usersStore.token !== "") {
      api.api.apisauce.headers.Authorization = `Bearer ${rootStore.usersStore.token}`;
    }
  });

  return (
    <HashRouter>
      <React.Suspense fallback={loading}>
        {usersStore.token ? (
          <Route
            path="/"
            name="Home"
            render={(props) => <DefaultLayout {...props} />}
          />
        ) : (
          <Route
            path="/"
            name="Login Page"
            render={(props) => <Login {...props} />}
          />
        )}
      </React.Suspense>
    </HashRouter>
  );
});

export default App;
