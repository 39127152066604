import { types, Instance, SnapshotOut } from "mobx-state-tree";
import api from "services/api/api";

export const WeatherModel = types
  .model("Weathers")
  .props({
    temperature: types.maybeNull(types.number),
    humidity: types.maybeNull(types.number),
    pressure: types.maybeNull(types.number),
    pollution: types.maybeNull(types.number),
    UV: types.maybeNull(types.number),
    lux: types.maybeNull(types.number),
    noise: types.maybeNull(types.number),
    isLoading: types.maybeNull(types.boolean),
    PM10: types.maybeNull(types.number),
    PM25: types.maybeNull(types.number),
    windDirection: types.maybeNull(types.string),
    windSpeed: types.maybeNull(types.number),
  })
  .actions((self) => {
    function setWeather(payload: any) {
      self.temperature = payload.temperature;
      self.humidity = payload.humidity;
      self.pressure = payload.pressure;
      self.pollution = payload.pollution;
      self.UV = payload.UV;
      self.noise = payload.noise;
      self.lux = payload.lux;
      self.PM10 = payload.PM10;
      self.PM25 = payload.PM25;
      self.windDirection = payload.windDirection;
      self.windSpeed = payload.windSpeed;
    }
    function setLoading(loading: any) {
      self.isLoading = loading;
    }
    return {
      setWeather,
      setLoading,
    };
  })
  .actions((self) => ({
    getWeather: async () => {
      try {
        self.setLoading(true);
        const weather = api.weather;
        const response = await weather.getCard();
        self.setWeather(response?.answer);
        self.setLoading(false);
      } catch (error) {
        console.log(error);
      }
    },
  }));

type WeathersModelType = Instance<typeof WeatherModel>;
export type Weathers = WeathersModelType;
type WeathersSnapshotType = SnapshotOut<typeof WeatherModel>;
export type WeathersSnapshot = WeathersSnapshotType;
export const createWeatherDefaultModel = () =>
  types.optional(WeatherModel, {
    temperature: 0,
    humidity: 0,
    pressure: 0,
    pollution: 0,
    UV: 0,
    noise: 0,
    windSpeed: 0,
    windDirection: "",
    PM10: 0,
    PM25: 0,
    lux: 0,
  });
