import { types, Instance, SnapshotOut } from "mobx-state-tree";
import api from "services/api/api";

const CO = types.model("co").props({
  green: types.maybeNull(types.number),
  yellow: types.maybeNull(types.number),
  red: types.maybeNull(types.number),
});

const CH4 = types.model("ch4").props({
  green: types.maybeNull(types.number),
  yellow: types.maybeNull(types.number),
  red: types.maybeNull(types.number),
});

export const GasModel = types
  .model("Gas")
  .props({
    title: types.maybeNull(types.string),
    co: types.maybeNull(CO),
    ch4: types.maybeNull(CH4),
    isLoading: types.boolean,
  })
  .actions((self) => {
    function setGas(payload: any) {
      self.title = payload.title;
      self.co = payload.CO;
      self.ch4 = payload.CH4;
    }
    function setLoading(isLoading: any) {
      self.isLoading = isLoading;
    }
    return {
      setGas,
      setLoading,
    };
  })
  .actions((self) => ({
    getCard: async () => {
      try {
        self.setLoading(true);
        const gas = api.gas;
        const response = await gas.getCard();
        self.setGas(response?.answer);
        self.setLoading(false);
      } catch (error) {
        console.log(error);
      }
    },
  }));

type GasModelType = Instance<typeof GasModel>;
export type Gas = GasModelType;
type GasSnapshotType = SnapshotOut<typeof GasModel>;
export type GasSnapshot = GasSnapshotType;
export const createGasDefaultModel = () =>
  types.optional(GasModel, {
    title: "",
    co: null,
    ch4: null,
    isLoading: false,
  });
