import { ApiResponse } from "apisauce";
import { IUser } from "models/interfaces/user";
import { Api } from "./api";
import { getGeneralApiProblem } from "./api-problem";

export class LoginApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  async login(usuario: IUser): Promise<any> {
    const payload = {
      user: usuario.user,
      pass: usuario.pass,
      clientid: usuario.clientid,
    };

    try {
      const response: ApiResponse<never> = await this.api.apisauce.post(
        `smartcities/user`,
        payload
      );
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const { data } = response;
      return data;
    } catch (e) {
      return { kind: "bad-data" };
    }
  }
}
