import { types, Instance, SnapshotOut } from "mobx-state-tree";
import api from "services/api/api";

export const ParkingsModel = types
  .model("Parkings")
  .props({
    totalSensor: types.maybeNull(types.number),
    busy: types.maybeNull(types.number),
    free: types.maybeNull(types.number),
    nameSensor: types.maybeNull(types.string),
    isLoading: types.maybeNull(types.boolean),
  })
  .actions((self) => {
    function setParkings(payload: {
      totalSensor: number;
      busy: number;
      free: number;
      nameSensor: string;
      isLoading: boolean;
    }) {
      self.totalSensor = payload.totalSensor;
      self.busy = payload.busy;
      self.free = payload.free;
      self.nameSensor = payload.nameSensor;
    }
    function setLoading(loading: boolean) {
      self.isLoading = loading;
    }
    return {
      setParkings,
      setLoading,
    };
  })
  .actions((self) => ({
    getCard: async () => {
      try {
        self.setLoading(true);
        const parking = api.parking;
        const response = await parking.getCard();
        self.setParkings(response?.answer);
        self.setLoading(false);
      } catch (error) {
        console.log(error);
      }
    },
  }));

type ParkingsModelType = Instance<typeof ParkingsModel>;
export type Parkings = ParkingsModelType;
type ParkingsSnapshotType = SnapshotOut<typeof ParkingsModel>;
export type ParkingsSnapshot = ParkingsSnapshotType;
export const createParkingDefaultModel = () =>
  types.optional(ParkingsModel, {
    totalSensor: 0,
    busy: 0,
    free: 0,
    nameSensor: "",
    isLoading: false,
  });
